import React, { useEffect } from "react"
import MessengerChat from "../components/messenger"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/common/header"
import { Div, Text, Image, Button, Anchor } from "atomize"
import TaiChinhImage1 from "../images/taichinh.jpg"
import { PrimaryColor } from "../components/colors"
import Page1 from "../images/uCall x Bat Dong San_page-0001.jpg"
import Page2 from "../images/uCall x Bat Dong San_page-0002.jpg"
import firebase from "gatsby-plugin-firebase"
import { Helmet } from "react-helmet"
const BatDongSan = () => {
  useEffect(() => {
    firebase.analytics().logEvent("visit_bat_dong_san")
  }, [])
  return (
    <Layout>
      <MessengerChat />
      <Helmet>
        <meta
          property="og:title"
          content="uCall - Giải pháp callbot gọi tự động cho ngành bất động sản"
        />
        <meta
          property="og:description"
          content="Giải pháp giúp doanh nghiệp gọi hàng nghìn cuộc gọi tự động, thông minh với 1 cú click chuột"
        />
      </Helmet>
      <SEO
        title="uCall - Giải pháp callbot gọi tự động cho ngành bất động sản"
        description="uCall - Giải pháp giúp doanh nghiệp gọi hàng nghìn cuộc gọi tự động, thông minh với 1 cú click chuột"
      />
      <Header layout={"layout-1"} />
      <Div
        m="auto"
        p={{
          t: { xs: "6rem", md: "10rem" },
          l: { xs: "1.5rem", md: "2rem" },
          r: { xs: "1.5rem", md: "2rem" },
        }}
        flexDir="row"
        maxW={"800px"}
      >
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Giải pháp callbot gọi tự động cho ngành bất động sản
        </Text>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/UCigfFZImig"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <a
          href={Page1}
          target="_blank"
          onClick={() => {
            firebase.analytics().logEvent(`tap_on_page_1_bat_dong_san`)
          }}
        >
          <Image src={Page1} w="100%" />
        </a>

        <a
          href={Page2}
          target="_blank"
          onClick={() => {
            firebase.analytics().logEvent(`tap_on_page_2_bat_dong_san`)
          }}
        >
          <Image src={Page2} w="100%" />
        </a>
        <div className="blink">
          <Anchor
            href="https://growth.ucall.vn/"
            onClick={() => {
              firebase
                .analytics()
                .logEvent("tap_on_trai_nghiem_ngay_bat_dong_san")
            }}
            target="_blank"
          >
            <Button
              bg="brand900"
              hoverBg="brand900"
              textColor="medium"
              w="100%"
              rounded="lg"
              m={{ t: "16px", b: "16px" }}
              style={{ letterSpacing: "-0.5px" }}
              onClick={() => {
                firebase
                  .analytics()
                  .logEvent("tap_on_trai_nghiem_ngay_bat_dong_san")
              }}
            >
              Trải Nghiệm Ngay
            </Button>
          </Anchor>
        </div>
        <Text>
          Bất động sản là lĩnh vực cạnh tranh nhất mà nhà tư vấn cần phải đẩy
          mạnh quảng cáo tiếp thị. Với lợi thế có nguồn data khách hàng lớn,
          telesales là kênh tiếp cận trực tiếp hiệu quả, tỉ lệ chuyển đổi từ
          kênh telesales cao hơn so với hình thức khác. Tuy nhiên, việc triển
          khai telesales thủ công trong doanh nghiệp A còn gặp nhiều hạn chế vì:
        </Text>
        <ul>
          <li> Khó quản lý chất lượng cuộc gọi từ nhân viên telesales</li>
          <li>
            Cảm xúc của nhân viên telesales có thể ảnh hưởng đến chất lượng cuộc
            gọi
          </li>
          <li>
            Tốn nhiều chi phí nhân sự và thời gian, chưa kể đến các chi phí vận
            hành, quản lý
          </li>
        </ul>
        <Text>
          Một bài toán với doanh nghiệp hoạt động trong lĩnh vực bất động sản và
          đầu tư thường xuyên phải trải qua là gọi điện cho người có tiền tiết
          kiệm để khảo sát nhu cầu quan tâm của họ tới việc đầu tư bất động sản.
        </Text>
        <Text>
          Trung bình, một doanh nghiệp có thể gọi 5000 số một ngày, tiêu tốn
          lượng chi phí 60 triệu/tháng và cần tới 15 nhân sự.
        </Text>
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color={PrimaryColor}
          m={{ b: "1rem", t: "2rem" }}
        >
          Giải Pháp
        </Text>
        <Text>
          Ứng dụng callbot của uCall để tự động hóa các chiến dịch telesales
        </Text>
        <ul>
          <li>Nhanh chóng, dễ dàng tự động gọi hàng ngàn cuộc mỗi ngày.</li>
          <li>Phân loại lead tiềm năng và không tiềm năng</li>
          <li>
            Lưu trữ, cập nhật và báo cáo kết quả cuộc gọi với các thông tin câu
            trả lời được chuyển dưới dạng text Dễ dàng nghe lại cuộc gọi
          </li>
        </ul>
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color={PrimaryColor}
          m={{ b: "1rem", t: "2rem" }}
        >
          Kết Quả Đạt Được
        </Text>
        <ul>
          <li>
            Sử dụng callbot của uCall giúp doanh nghiệp tối ưu 50% chi phí và
            thời gian so với tổng đài truyền thống (cắt giảm chi phí nhân sự,
            chi phí quản lý, vận hành)
          </li>
          <li>Tăng năng suất gấp đôi so với tổng đài viên thuê ngoài</li>
          <li>
            Nhanh chóng hoàn thành nhiệm vụ, gọi điện 5000 cuộc chỉ trong 1h
          </li>
          <li>
            Đặc biệt, so với người gọi khó kiểm soát chất lượng do nhân viên
            telesales có thể có cảm giác buồn bực, chán nản sau khi bị khách từ
            chối, hoặc cảm xúc ảnh hưởng đến chất lượng cuộc gọi thì tổng đài tự
            động của uCall giúp doanh nghiệp có thể dễ dàng kiểm soát chất lượng
            cuộc gọi.
          </li>
        </ul>
        <Div h="120px" />
      </Div>
      <a href="tel:+84327335877" className="call-to-us">
        <div className="call-to-us__label">Liên Hệ</div>
      </a>
    </Layout>
  )
}

export default BatDongSan
